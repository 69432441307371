import React from "react"
import Layout from "../components/layouts"
import "../stylesheets/pages/_404.scss";

export default () => (
  <Layout>
    <div className="not-found">
  		<h1>Page not found</h1>
  		<h2>Let us guide you back to the start</h2>
  		<p><a href="/">Return to Homepage</a></p>
  	</div>
  </Layout>
)
